<script src="job-view.component.ts"></script>
<div class="main">
    <div class="overflow-x-auto">
        <div class="flex flex-nowrap items-center space-x-2 overflow-x-auto pb-4 bg-white w-full">
            <div class="flex gap-4 w-full">
                <div class="relative sm:w-auto">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input type="search" [(ngModel)]="searchParams().pp_number"
                           class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-10 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                           placeholder="PP Number">
                </div>
                <div class="flex-shrink-0">
                    <button (click)="fetchPOData()" type="submit"
                            class="button-blue inline-flex items-center whitespace-nowrap">
                        <span class="hidden sm:inline">Search</span>
                    </button>
                </div>
                <div class="ml-auto">
                    <button type="button" class="button-blue inline-flex items-center whitespace-nowrap"
                            (click)="openFileUploadModal()">
                        Upload File
                    </button>
                </div>
                <div class="">
                    <button type="button" class="button-blue inline-flex items-center whitespace-nowrap"
                            (click)="openAddjobModal()">
                        Add a Job
                    </button>
                </div>
            </div>
        </div>

        <div class="table-wrapper overflow-x-auto">
            <table class="table">
                <thead class="table-thead">
                <tr>
                    <th scope="col" class="px-6 py-3">Job Number</th>
                    <th scope="col" class="px-6 py-3">SPP Number</th>
                    <th scope="col" class="px-6 py-3">Width</th>
                    <th scope="col" class="px-6 py-3">Length</th>
                    <th scope="col" class="px-6 py-3">Quantity</th>
                    <th scope="col" class="px-6 py-3">Request Date</th>
                    <th scope="col" class="px-6 py-3">Description</th>
                    <th scope="col" class="px-6 py-3">No Of Breakdowns</th>
                    <th scope="col" class="px-6 py-3">Job Status</th>
                </tr>
                </thead>
                <tbody>
                    @for (jobs of jobCardFindDTOS(); track jobs) {
                        <tr class="bg-white border-b">
                            <td class="px-6 py-2 text-sm">{{ jobs.ppNo | uppercase }}
                            <td class="px-6 py-2 text-sm">{{ jobs.sppNo }}
                            <td class="px-6 py-2 text-sm">{{ jobs.width }}
                            <td class="px-6 py-2 text-sm">{{ jobs.length }}
                            <td class="px-6 py-2 text-sm">{{ jobs.quantity }}
                            <td class="px-6 py-2 text-sm">{{ jobs.requestDate | uppercase }}
                            <td class="px-6 py-2 text-sm">{{ jobs.jobDescription || '-' | uppercase }}
                            <td class="px-6 py-2 text-sm">{{ jobs.noOfBreakdowns }}
                            <td class="px-6 py-2 text-sm">
                                <app-status-badge [jobStatus]="jobs.status"></app-status-badge>
                            </td>   
                        </tr>
                    } @empty {
                        <tr>
                            <td colspan="12" class="px-6 py-10">
                                <div class="flex flex-col items-center justify-center text-center">
                                    <p class="text-xl font-semibold text-gray-700 mb-2">No Jobs
                                        Found</p>
                                    <p class="text-gray-500">We couldn't find any pending jobs that match
                                        your search criteria.</p>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <app-pagination [totalItems]="$$totalItems()" [itemsPerPage]="$$itemsPerPage()"
                            (pageChanged)="onPageChange($event)"></app-pagination>

        </div>
    </div>

    @if (modal.$$showFileUploadModal()){
        <div id="operation-modal" tabindex="-1" aria-hidden="true" class=" fixed inset-0 z-50 flex justify-center items-center overflow-y-auto bg-gray-900 bg-opacity-50">
            <div class="relative p-4 w-full max-w-5xl mx-auto bg-white rounded-lg shadow-lg">
                <div class="relative">
                    <div class="flex items-center justify-between p-4 border-b rounded-t">
                        <h3 class="text-lg font-semibold text-gray-900 flex items-center">
                            <span>Job Card Upload</span>
                            <span class="status-badge ml-4"></span>
                        </h3>
                        <button type="button" (click)="modal.$$showFileUploadModal.set(false)"
                                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
                                data-modal-toggle="crud-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div class="pt-4">
                        <div class="p-4">
                            <div class="flex flex-col items-center justify-center w-full">
                                <label
                                  for="dropzone-file"
                                  class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2"
                                  role="button"
                                  aria-describedby="file-upload-instructions"
                                >
                                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                      class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 20 16"
                                    >
                                      <path
                                        d="M13 13h3a3 3 0 000-6h-.025A5.56 5.56 0 0016 6.5 5.5 5.5 0 005.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 000 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                      />
                                    </svg>
                                    <p id="file-upload-instructions" class="mb-2 text-xl text-gray-500">
                                      <span class="font-semibold">Click to upload</span> or drag and drop
                                    </p>
                                    <p class="text-sm text-gray-500">
                                      Only Excel (.xlsx, .xls) and CSV (.csv) files are allowed
                                    </p>
                                  </div>
                                  <input
                                    id="dropzone-file"
                                    type="file"
                                    class="hidden"
                                    (change)="onFileSelected($event)"
                                    accept=".xlsx,.xls,.csv"
                                  />
                                </label>
                              </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    @if (modal.$$showAddJobModal()){
        <div id="operation-modal" tabindex="-1" aria-hidden="true" class=" fixed inset-0 z-50 flex justify-center items-center overflow-y-auto bg-gray-900 bg-opacity-50">
            <div class="relative p-4 w-full max-w-5xl mx-auto bg-white rounded-lg shadow-lg">
                <div class="relative">
                    <div class="flex items-center justify-between p-4 border-b rounded-t">
                        <h3 class="text-lg font-semibold text-gray-900 flex items-center">
                            <span>Add a Job Card</span>
                            <span class="status-badge ml-4"></span>
                        </h3>
                        <button type="button" (click)="modal.$$showAddJobModal.set(false)"
                                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
                                data-modal-toggle="crud-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div class="pt-4 px-8">
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <span class="font-semibold text-gray-600 mb-4 text-sm">Job Number <span class="text-red-500 text-lg">*</span></span>
                                <input type="text" [(ngModel)]="jobData.ppNumber"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="PP Number">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 mb-4 text-sm">SPP Number <span class="text-red-500 text-lg">*</span></span>
                                <input type="text" [(ngModel)]="jobData.sppNo"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="SPP Number">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 mb-4 text-sm">Reference <span class="text-red-500 text-lg">*</span></span>
                                <input type="text" [(ngModel)]="jobData.reference"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Reference">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 text-sm">Job Description <span class="text-red-500 text-lg">*</span></span>
                                <input type="text" [(ngModel)]="jobData.jobDescription"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Job Description">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 text-sm">Quantity <span class="text-red-500 text-lg">*</span></span>
                                <input type="text" [(ngModel)]="jobData.quantity"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Quantity">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 text-sm">Breakdowns <span class="text-red-500 text-lg">*</span></span>
                                <input type="text" [(ngModel)]="jobData.noOfBreakdowns"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Breakdowns">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 text-sm">Length(mm) <span class="text-red-500 text-lg">*</span></span>
                                <input type="text" [(ngModel)]="jobData.length"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Length(mm)">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 text-sm">Width(mm) <span class="text-red-500 text-lg">*</span></span>
                                <input type="text" [(ngModel)]="jobData.width"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Width(mm)">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 text-sm">Customer Request Date <span class="text-red-500 text-lg">*</span></span>
                                <input type="date" [(ngModel)]="jobData.requestDate"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50">
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 text-sm">Finishings</span>
                                <input type="text" [(ngModel)]="jobData.finishing"
                                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-3 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Finishings">
                            </div>
                        </div>                        
                        
                        <div class="pb-2 pt-4">
                            <div class="flex justify-end space-x-3">
                                <button
                                        type="button"
                                        class="button-blue"
                                        (click)="submitForm()"
                                >
                                    Save
                                </button>
                                <button
                                        type="button"
                                        class="button-red"
                                        (click)="closeAddjobModal()"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
