import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, catchError, take, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {APIRequestResources, CachedAPIRequest} from "../../../core";
import {handleError} from "../../../core/utils/utils";
import {DepartmentDTO, Departments} from "../interface/department.entity";



@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<Departments[]>([])
  all = toSignal(this.$all, {initialValue: []})

  $active = new BehaviorSubject<any | undefined>(undefined);
  active = toSignal(this.$active, {initialValue: undefined});

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.DepartmentService)
  }

  getAllDepartments(refresh = false) {
    return this.get<Departments>({}, refresh ? 'freshness' : 'performance')
        .pipe(
            tap(res => this.$all.next([{ departments: res.data.departments }])),
            catchError(handleError)
        );
  }

  getById = (id: string, refresh = true) => {
      return this.get<DepartmentDTO>({id}, refresh ? 'freshness' : 'performance')
          .pipe(tap((res) => this.$active.next(res.data)),);
  }

}
