import {Component} from '@angular/core';


@Component({
    selector: 'app-handle-modals',
    imports: [

    ],
    templateUrl: './handle-modals.component.html',
    standalone: true,
    styleUrl: './handle-modals.component.scss'
})
export class HandleModalsComponent {

}
