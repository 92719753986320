import {Routes} from '@angular/router';
import {JobViewComponent} from "./modules";
import { OperationCreateComponent } from './modules/operation-create/components/operation-create/operation-create.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'job-card-upload',
        pathMatch: 'full'
    },
    {
        path: '',
        component: JobViewComponent,
    },
    {
        path: 'job-card-upload',
        component: JobViewComponent,
    },
    {
        path: 'job-operation',
        loadChildren: () => import('./modules/job-operation/job-operation.module')
            .then(m => m.JobOperationModule),
        data: {title: 'Job Operation'}
    },
    {
        path: 'operation-create',
        component: OperationCreateComponent,
    }

];
