import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { catchError, tap, of } from 'rxjs';
import { LoadingService, NotificationService } from 'src/app/core';
import { JobCardService } from 'src/app/modules/job-operation/services/job-card.service';
import { PaginationComponent } from 'src/app/core';
import { JobCardFindDTO, JobCardGetDTO } from 'src/app/modules/job-operation/interfaces/job-card.entity';
import { DepartmentDTO, DepartmentGetDTO } from 'src/app/modules/shared/interface/department.entity';
import { DepartmentService } from 'src/app/modules/shared/services/department.service';
import { JobOperationService } from 'src/app/modules/shared/services/job-operation.service';
import { JobOperationItemDTO } from 'src/app/modules/shared/interface/jop-operation.entity';

@Component({
  selector: 'app-operation-create',
  imports: [
    CommonModule,
    FormsModule,
    PaginationComponent
  ],
  templateUrl: './operation-create.component.html',
  styleUrl: './operation-create.component.scss',
  standalone: true
})
export class OperationCreateComponent {

  jobCardService = inject(JobCardService);
  notification = inject(NotificationService);
  loading = inject(LoadingService);
  departmentService = inject(DepartmentService);
  jobOperationService = inject(JobOperationService); 

  protected $$showFileUploadModal = signal(false);
  protected selectedDepartmentId = signal<number | null>(null);
  protected jobCardFindDTOS = signal<JobCardFindDTO[]>([]);
  protected jobCardGetDTOS = signal<JobCardGetDTO[]>([]);
  protected JobOperationItemDTOS = signal<JobOperationItemDTO[]>([]);
  protected $$departmentDTO = signal<DepartmentDTO[]>([]);
  protected DepartmentGetDTO = signal<DepartmentGetDTO[]>([]);
  protected selectedJobDetails: any = null;

  selectedDeliverDate: string = '';
  saveMessage: string | null = null;
  errorMessage = signal<string | null>(null);
  department_id: number | null = null;


  protected readonly $$totalItems = signal(0);
  protected readonly $$pageNumber = signal(1);
  protected readonly $$itemsPerPage = signal(1);

  selectedYear = signal(new Date().getFullYear());
  selectedMonth = signal(new Date().getMonth());

  calendarDays: Date[] = [];
    months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    years: number[] = [];

  minDate: string = new Date().toISOString().split('T')[0];
  


  protected searchParams = signal({
          pp_number: '',
          status: 'pending',
          delivery_date: '',
          machine_id: -1,
          department_id: -1,
          page_number: 1,
          items_per_page: 15
      });

      protected searchParamsOp = signal({
        pp_number: '',
        status: '',
        machine_id: '',
        department_id: '',
        page_number: 1,
        items_per_page: 10
    });

    protected finishings = signal<{ name: string; departmentId: number | null; machineId: number | null; endDate: string;  }[]>([]);


    protected departmentsDetails = signal<{ 
        departmentId: number | null; 
        departmentCode: string;
        departmentName: string; 
        departmentStatus: string; 
        stages: { stageId: number; stageName: string; stageStatus: string; endDate: string }[] | null;
      }[]>([]);

    
    protected selectedStages = signal<{ stageId: number; stageName: string; stageStatus: string; estimatedDateTime:string }[]>([]);

    

    constructor() {
        this.jobCardService.getRefreshObservable().subscribe(() => {
            this.fetchPOData();
        });
        this.fetchDepartment();
        this.generateCalendar();
        this.populateYears();
    }


    onDepartmentChange(departmentId: number): void {
        const selectedDepartment = this.$$departmentDTO().find(
            (dept) => dept.departmentId === Number(departmentId)
        );
        this.getOperationByDep(departmentId);
        if (selectedDepartment) {
            //console.log('Selected Department Stages:', selectedDepartment.stages);
            this.selectedStages.set(selectedDepartment.stages || []);
        }
    }
    
    populateYears(): void {
        const currentYear = new Date().getFullYear();
        this.years = Array.from({ length: 11 }, (_, i) => currentYear + i);
    }
    

    generateCalendar(): void {
        const year = this.selectedYear();
        const month = this.selectedMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        this.calendarDays = Array.from({ length: daysInMonth }, (_, i) => new Date(year, month, i + 1));
    }

    updateCalendar(): void {
        this.generateCalendar();
    }

    isSameDate(dateString: string, day: Date): boolean {
        const date = new Date(dateString);
        return date.toDateString() === day.toDateString();
    }


    protected getOperationByDep(ID: number | null): void {
        this.departmentService.getById(String(ID)).pipe(
            tap(response => {
                this.loading.set(false);
               this.DepartmentGetDTO.set([response.data]);
            }),
            
            catchError(error => {
                this.loading.set(false);
                this.notification.set({type: 'error', message: `Failed to load Purchase Order details`});
                console.error(error);
                return of(null);
            })
        ).subscribe();
        
    }
  public fetchPOData(): void {
          this.jobCardService.find(this.searchParams()).pipe(
              tap(response => {
                  this.jobCardFindDTOS.set(response.data.data);
                  this.$$totalItems.set(response.data.totalItems);
                  this.$$pageNumber.set(response.data.page);
                  this.$$itemsPerPage.set(response.data.itemsPerPage);
              }),
              catchError(error => {
                  this.notification.set({ type: 'error', message: `Failed to fetch PO data` });
                  console.error(error);
                  return of(null);
              })
          ).subscribe();
      }

  public onPageChange(pageNumber: number): void {
      const updatedParams = {
          ...this.searchParams(),
          page_number: Number(pageNumber)
      };
      this.searchParams.set(updatedParams);
      this.fetchPOData();
  }

  protected getJobDetails(ID: number): void {
    this.$$showFileUploadModal.set(true);
    this.jobCardService.getById(String(ID)).pipe(
        tap(response => {
            this.loading.set(false);
            this.jobCardGetDTOS.set([response.data]);
            this.selectedJobDetails = response.data;

        }),
        
        catchError(error => {
            this.loading.set(false);
            this.notification.set({type: 'error', message: `Failed to load Purchase Order details`});
            console.error(error);
            return of(null);
        })
    ).subscribe();
    
}


public fetchDepartment(): void {
    this.departmentService.getAllDepartments().subscribe({
        next: (result) => {
            if (result.data && result.data.departments) {
                const departments = result.data.departments;
                this.$$departmentDTO.set(departments);
                console.log(this.$$departmentDTO())

                const formattedDepartments = departments.map((dept: any) => ({
                    departmentId: dept.departmentId,
                    departmentCode: dept.departmentCode,
                    departmentName: dept.departmentName,
                    departmentStatus: dept.departmentStatus,
                    stages: dept.stages || null, 
                  }));
              
                  this.departmentsDetails.set(formattedDepartments);

            }
        },
        error: (error) => {
            console.error('Error fetching departments:', error);
        }
    });
}

    protected selectedPPNo: string | null = null;

    protected onJobRowClick(ppNo: string): void {
        this.selectedPPNo = ppNo;
    }

    saveMachineDetails(): void {
            const jobOperations = this.selectedStages().map((stage, index) => ({
                sequence: index + 1,
                estimatedDateTime: stage.estimatedDateTime,
                deliverDate: this.selectedDeliverDate,
                jobId:  this.selectedJobDetails.id,
                stageId: stage.stageId,
                departmentId: this.selectedDepartmentId(),
            }));

            console.log({jobOperations})

            this.jobOperationService.create({ jobOperations }).subscribe(
                response => {
                    this.notification.set({type: 'success', message: `Job operation saved successfully.`});
                    this.jobCardService.triggerRefresh();
                    console.log('Job Operation saved successfully', response);

                    this.selectedStages().forEach(stage => {
                        stage.estimatedDateTime = '';
                    });
                    this.selectedDeliverDate = ''; 
                    this.selectedJobDetails = null;
                    this.$$showFileUploadModal.set(false);
                    this.selectedDepartmentId.set(null)
                },
                error => {
                    this.notification.set({type: 'error', message: `Failed to save job operation`});
                    console.error('Error saving jobs', error);
                    this.errorMessage.set(`Error saving jobs: ${error.message || 'Unknown error'}`);

                }
                );
        
    }

    

    protected formatDateTime(date: string): string {
        return `${date}`;
        }

    
}
