<script src="operation-create.component.ts"></script>
<div class="flex flex-row">

    <!-- left side -->
    <div class="bg-white rounded-lg ml-6 mt-4 p-4 w-[25vw]">
        <div class="overflow-x-auto">
            <div class="flex flex-nowrap items-center space-x-2 overflow-x-auto pb-4 bg-white w-full">
                <div class="relative sm:w-auto">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input type="search" [(ngModel)]="searchParams().pp_number"
                            class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full pl-10 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                            placeholder="PP Number">
                </div>
                <div class="flex-shrink-0">
                    <button (click)="fetchPOData()" type="submit"
                            class="button-blue inline-flex items-center whitespace-nowrap">
                        <span class="hidden sm:inline">Search</span>
                    </button>
                </div>  
            </div>

            <div class="overflow-x-auto">
                <div class="table-wrapper overflow-x-auto">
                    <table class="table">
                        <thead class="table-thead">
                            <tr>
                                <th scope="col" class="px-6 py-3">Job Number</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            @for (jobs of jobCardFindDTOS(); track jobs) {
                                <tr class="bg-white border-b hover:bg-blue-100 cursor-pointer">
                                    <td class="px-6 py-2 text-sm" (click)="getJobDetails(jobs.id)" (click)="onJobRowClick(jobs.ppNo)">{{ jobs.ppNo | uppercase }}
                                    
                                </tr>
                            } @empty {
                                <tr>
                                    <td colspan="12" class="px-6 py-10">
                                        <div class="flex flex-col items-center justify-center text-center">
                                            <p class="text-xl font-semibold text-gray-700 mb-2">No Jobs
                                                Found</p>
                                            <p class="text-gray-500">We couldn't find any pending jobs that match
                                                your search criteria.</p>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    
                    
                    <app-pagination [totalItems]="$$totalItems()" [itemsPerPage]="$$itemsPerPage()"
                                    (pageChanged)="onPageChange($event)"></app-pagination>
        
                </div>
            </div>
        </div>
    </div>

    <!-- right side -->
    <div class="main w-[75vw]">
        <div class="overflow-x-auto">
            @if($$showFileUploadModal()){
                <h3 class="text-lg font-semibold text-gray-900 flex items-center">
                    <span>Create Job Operation {{ selectedPPNo }}</span>
                    <span class="status-badge ml-4"></span>
                </h3>
                <div class="pt-4">
                    

                    <div class="flex justify-between p-2 rounded-t flex flex-col mb-4 px-8 py-4 bg-gray-50 rounded-lg">
                        <div class="flex flex-row w-full">
                            <h3 class="text-sm font-semibold mb-3 text-gray-700 flex items-center gap-2 w-full">
                                Select Department
                            </h3>
                            <div class="flex flex-col w-full mb-4">
                                <select id="department" class="textField flex-1" [(ngModel)]="selectedDepartmentId" (ngModelChange)="onDepartmentChange($event)">
                                    <option value="null" hidden disabled selected>Choose a Department</option>
                                    <option *ngFor="let dept of $$departmentDTO()" [value]="dept.departmentId">
                                        {{ dept.departmentName | titlecase }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="mt-2 mb-2 w-full text-sm font-semibold text-gray-700">Add Estimate End Dates for Stages</div>
                        
                        
                        <div *ngIf="selectedStages().length > 0">
                            <div class="flex flex-col space-y-2">
                                <div *ngFor="let stage of selectedStages(); let i = index" class="flex flex-row items-center gap-4 w-full">
                                    <h3 class="text-sm font-semibold text-gray-700 flex-1">
                                        {{ stage.stageId }}. {{ stage.stageName | titlecase }}
                                    </h3>
                                    <input type="date" class="textField flex-1" [(ngModel)]="stage.estimatedDateTime"/>
                                </div>
                            </div>

                            <div class="flex flex-row w-full mt-4">
                                <h3 class="text-sm font-semibold mb-3 text-gray-700 flex items-center gap-2 w-full">
                                    Select Deliver Date
                                </h3>
                                <div class="flex flex-col w-full mb-4">
                                    <input
                                            type="date"
                                            class="textField"
                                            [(ngModel)]="selectedDeliverDate"
                                            #deliverDate="ngModel"
                                            [min]="minDate"
                                            required
                                    />
                                </div>
                            </div>

                        </div>
                        
                        <div class="pb-2">
                            <div class="flex justify-end space-x-3">
                                <button
                                        type="button"
                                        class="button-blue"
                                        (click)="saveMachineDetails();"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="saveMessage" class="px-4 self-center pb-4">
                        <div
                                [ngClass]="{'text-success bg-green-50 border-green-200 text-green-600': saveMessage.startsWith('Job operation saved'),
                                            'text-danger bg-red-50 border-red-200 text-red-600': saveMessage.startsWith('Error')}"
                                class="rounded-lg p-4 border-green-200 text-sm mt-1 text-center ">
                            {{ saveMessage }}
                        </div>
                    </div>

                    <div class="mb-4 px-6  bg-gray-50 rounded-lg mt-2">
                        <div class="flex justify-end w-[350px] mb-4 gap-4 ml-auto pt-4">
                            <select [(ngModel)]="selectedYear" (change)="updateCalendar()" class="textField">
                                @for (year of years; track year) {
                                    <option [value]="year">{{ year }}</option>
                                }
                            </select>
                            <select [(ngModel)]="selectedMonth" (change)="updateCalendar()" class="textField">
                                @for (month of months; track $index) {
                                    <option [value]="$index">{{ month }}</option>
                                }
                            </select>
    
                        </div>
                        
                        <!-- Calendar Table -->
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th class="px-6 py-3 sticky left-0 bg-gray-50 z-10">Machines</th>
                                        <th class="px-6 py-3 text-center" *ngFor="let day of calendarDays">
                                            {{ day | date: 'MMM d' }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (dep of $$departmentDTO(); track dep) {
                                        <!-- @for (machine of dep.machines; track machine) {
                                            <tr class="border-b">
                                                <td class="px-6 py-2 text-sm font-medium sticky left-0 bg-gray-50 z-10">
                                                    {{ machine.machineName }}
                                                </td>
                                                <td *ngFor="let day of calendarDays" class="relative w-24 h-20 border">
                                                    <div class="flex flex-col space-y-1 max-h-full overflow-y-auto p-1">
                                                        @for (operation of machine.operations; track operation) {
                                                            <span *ngIf="isSameDate(operation.estimatedDateTime, day)"
                                                                class="px-2 py-1 text-xs text-white rounded-md"
                                                                [ngClass]="{
                                                                    'bg-green-500': operation.jobStatus === 'completed',
                                                                    'bg-sky-600': operation.jobStatus === 'in progress',
                                                                    'bg-gray-500': operation.jobStatus === 'pending',
                                                                    'bg-red-600': operation.jobStatus === 'hold'
                                                                }">
                                                                <span>{{ operation.jobNo }}</span>
                                                            </span>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        } -->
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            } @else {
                <div class="flex items-center justify-center h-96">
                    <div class="text-center">
                        <p class="text-xl font-semibold text-gray-700">No Job Selected</p>
                        <p class="text-gray-500">Please select a job to view and manage its operations.</p>
                    </div>
                </div>
            }            
            
        </div>
    </div>
</div>