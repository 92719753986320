import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobCardFindDTO } from 'src/app/modules/job-operation/interfaces/job-card.entity';
import { JobCardService } from 'src/app/modules/job-operation/services/job-card.service';
import { catchError, of, tap } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { FormsModule, NgForm } from '@angular/forms';
import { PaginationComponent } from 'src/app/core';
import { JobOperationService } from 'src/app/modules/shared/services/job-operation.service';
import { StatusBadgeComponent } from 'src/app/modules/shared/components/status-badge/status-badge.component';
import { ModalService } from 'src/app/modules/shared/services/modal.service';
import * as XLSX from 'xlsx';
import { JobCardDTO } from '../../interfaces/job.entity';

@Component({
    selector: 'app-job-view',
    imports: [
        CommonModule,
        FormsModule,
        PaginationComponent,
        StatusBadgeComponent,
    ],
    templateUrl: './job-view.component.html',
    standalone: true,
    styleUrl: './job-view.component.scss'
})
export class JobViewComponent {

    selectedFile: File | null = null;
    selectedFileName: string | null = null;
    errorMessage: string | null = null;
    saveMessage: string | null = null;

    loading = inject(LoadingService);
    jobCardService = inject(JobCardService);
    notification = inject(NotificationService);
    jobOperationService = inject(JobOperationService);
    public modal = inject(ModalService);

    protected jobCardFindDTOS = signal<JobCardFindDTO[]>([]);

    $$showFileUploadModal = signal(false);
    protected readonly $$totalItems = signal(0);
    protected readonly $$pageNumber = signal(1);
    protected readonly $$itemsPerPage = signal(1);

    protected searchParams = signal({
        pp_number: '',
        status: 'pending',
        delivery_date: '',
        machine_id: -1,
        department_id: -1,
        page_number: 1,
        items_per_page: 10
    });

    excelData: any[] = [];

    constructor() {
        this.jobCardService.getRefreshObservable().subscribe(() => {
            this.fetchPOData();
        });
    }

    openAddjobModal(): void {
      this.modal.$$showAddJobModal.set(true);
    }
    closeAddjobModal(): void {
      this.modal.$$showAddJobModal.set(false);
    }

    openFileUploadModal(): void {
        this.modal.$$showFileUploadModal.set(true);
    }

    closeFileUploadModal(): void {
        this.$$showFileUploadModal.set(false);
    }

    handleFileUpload(data: any[]): void {
        this.excelData = data;
        this.closeFileUploadModal();
        this.$$showFileUploadModal.set(false);
    }

    public fetchPOData(): void {
        this.jobCardService.find(this.searchParams()).pipe(
            tap(response => {
                this.jobCardFindDTOS.set(response.data.data);
                this.$$totalItems.set(response.data.totalItems);
                this.$$pageNumber.set(response.data.page);
                this.$$itemsPerPage.set(response.data.itemsPerPage);
                // console.log(this.jobCardFindDTOS())
            }),
            catchError(error => {
                this.notification.set({ type: 'error', message: `Failed to fetch PO data` });
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }

    public onPageChange(pageNumber: number): void {
        const updatedParams = {
            ...this.searchParams(),
            page_number: Number(pageNumber)
        };
        this.searchParams.set(updatedParams);
        this.fetchPOData();
    }

    // file upload methods

    onFileSelected(event: any): void {
        const file = event.target.files[0];
        if (file) {
          this.selectedFileName = file.name;
          this.selectedFile = file;
          this.errorMessage = null;
    
          const fileType = file.name.split('.').pop()?.toLowerCase();
          if (fileType === 'xlsx' || fileType === 'xls' || fileType === 'csv') {
            this.readFile(file);
          } else {
            this.errorMessage = 'Invalid file type. Only .xlsx, .xls, and .csv files are allowed.';
          }
        }
      }
    
      private readFile(file: File): void {
        const reader = new FileReader();
    
        reader.onload = (e: any) => {
          const binaryStr = e.target.result;
          const workbook = XLSX.read(binaryStr, { type: 'binary' });
    
          const sheetNames = workbook.SheetNames;
          const firstSheet = workbook.Sheets[sheetNames[0]];
          const rawJobData = XLSX.utils.sheet_to_json<any>(firstSheet);
    
          const jobData: JobCardDTO[] = rawJobData.map(row => ({
            ppNumber: row['Plan Card No'],
            reference: row['Sales Order Reference'],
            sppNo: row['Sample Code'],
            jobDescription: row['Invoice Details'],
            quantity: Number(row['Order Qty']),
            noOfBreakdowns: 0,
            length: parseFloat(row['Product Length'].match(/\d+(\.\d+)?/)?.[0] || '0'),
            width: parseFloat(row['Product Width'].match(/\d+(\.\d+)?/)?.[0] || '0'),
            requestDate:row['Customer Request Date'],
            finishing: '',

          }));
    
          console.log('Transformed job data:', jobData);
    
          this.saveJobs(jobData);
          
        };
        reader.readAsBinaryString(file);
      }
    
      private formatDate(inputDate: string): string {
        if (!inputDate) return '';

        const match = inputDate.match(/^(\d{4})(\d{2})(\d{2})/);
        if (match) {
          return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return inputDate.split(' ')[0];
      }
      
    
      private saveJobs(jobs: JobCardDTO[]): void {
      if (jobs && jobs.length > 0) {
        const payload = {
          jobCards: jobs,
        };
        console.log(payload)
        this.jobCardService.create(payload).subscribe(
          response => {
            console.log('Jobs saved successfully', response);
            this.jobCardService.triggerRefresh();
            this.notification.set({type: 'success', message: `Job Cards Uploaded Successfully!`});
            this.modal.$$showFileUploadModal.set(false);
          },
          error => {
            console.error('Error saving jobs', error);
            this.errorMessage = `Error saving jobs: ${error.message || 'Unknown error'}`;
            this.notification.set({type: 'error', message: `Failed to Upload Job Cards!`});
          }
        );
      } else {
        this.errorMessage = 'No valid jobs found to save.';
      }
    }

    jobData: JobCardDTO = {
      ppNumber: '',
      reference: '',
      sppNo: '',
      jobDescription: '',
      quantity: 1,
      noOfBreakdowns: 0,
      length: 0,
      width: 0,
      requestDate: '',
      finishing: ''
    };

    submitForm() {
        this.jobData.noOfBreakdowns = Number(this.jobData.noOfBreakdowns);
        this.jobData.length = Number(this.jobData.length);
        this.jobData.width = Number(this.jobData.width);
        this.jobData.quantity = Number(this.jobData.quantity);
      
        
      console.log(this.jobData)

      if (!this.isFormValid()) {
        this.notification.set({type: 'error', message: `Please fill all required fields before saving`});
        return;

      } else{
        this.saveJobs([this.jobData]);

        this.jobData = {
          ppNumber: '',
          reference: '',
          sppNo: '',
          jobDescription: '',
          quantity: 1,
          noOfBreakdowns: 0,
          length: 0,
          width: 0,
          requestDate: '',
          finishing: ''
      };
  
      this.modal.$$showAddJobModal.set(false);
  
        }
      
    }

    isFormValid(): boolean {
 
          if (!this.jobData.ppNumber || !this.jobData.sppNo || !this.jobData.length || !this.jobData.width || !this.jobData.jobDescription || !this.jobData.quantity || !this.jobData.requestDate || !this.jobData.reference || !this.jobData.noOfBreakdowns ) {
              return false;
          }
      
      return true;
  }
    

    
}
